
import React, { useState, useEffect } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography, TextField,
  InputAdornment, Button, MenuItem, Menu, IconButton, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, InputLabel, Select, FormHelperText,
  Dialog, DialogTitle, DialogContent, Grid, DialogActions, styled,
  Tooltip
} from '@mui/material';
import Topbar from '../../superadmin/layouts/Topbar';
import '../../admin/styles/pages/dashboard.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import instance from "../../utils/api";
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Managers = () => {
  //filter
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [managers, setmanagers] = useState([]);//usestate for Departments
  const [loading, setLoading] = useState(true);// State to handle loading
  const [sortOrder, setSortOrder] = useState('asc');  // array sorting
  const [sortBy, setSortBy] = useState('');
  const [page, setPage] = useState(1);
  const rowsPerPage = 10; // Fixed rows per page
  const [searchTerm, setSearchTerm] = useState('');  // Search
  const [firstName, setfirstName] = useState(""); //usesate for create managers
  const [lastName, setlastName] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [email, setemail] = useState("");
  const [adharNumber, setadharNumber] = useState("");
  const [departmentId, setdepartmentId] = useState("");
  const [departments, setDepartments] = useState([]); //usestate for Departments
  const startIndex = (page - 1) * rowsPerPage;// Calculate the start and end index for pagination
  const endIndex = page * rowsPerPage;
  const changestatus = "Manager Change Status"; // Define the tooltip text
  const tooltipmanager = "Create Manager Dialog"
  const [open1, setOpen1] = useState(false); //create managers popup
  const [firstNameError, setfirstNameError] = useState("");     //validation  firstNameError
  const [lastNameError, setlastNameError] = useState(""); //validation  lastNameError
  const [mobileNoError, setmobileNoError] = useState(""); //validation  firstNameError
  const [emailError, setemailError] = useState(""); //validation  mobileNoError
  const [adharNumberError, setadharNumberError] = useState(""); //validation  adharNumberError
  const [departmentIdError, setdepartmentIdError] = useState(""); //validation  departmentIdError
  const [openDialog, setOpenDialog] = useState(false);  //usestate for update
  const [selectedmanager, setSelectedmanager] = useState(null);  //usestate for update
  const [age, setAge] = useState("");
  const [value, setValue] = useState(dayjs("2022-04-17"));   //datejs

  // upadate manager status function
  const handleopenDialog = (manager) => {
    setOpenDialog(true);
    setSelectedmanager(manager);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedmanager(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //filter-dropdown
  const handleChange = (event) => {
    setAge(event.target.value);
  };


  //add manager popup open
  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  //add manager popup close
  const handleClose1 = () => {
    setOpen1(false);
    //clear fields
    setfirstName("");
    setlastName("");
    setemail("");
    setmobileNo("");
    setadharNumber("");
    setdepartmentId("");
    //clear validationError
    setfirstNameError("");
    setlastNameError("");
    setemailError("");
    setmobileNoError("");
    setadharNumberError("");
    setdepartmentIdError("")

  };
  //page function
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1); // Adjust to be 1-based
  };

  // Utility function to add leading zeros
  const formatNumber = (num, size) => {
    let s = String(num);
    while (s.length < size) s = "0" + s;
    return s;
  };


  //get departments api call
  const fetchmanagers = async () => {
    try {
      const response = await instance.get(`Commissioner/getManagers`);
      setmanagers(response.data.managers);
    } catch (error) {
      console.error('Error fetching managers:', error.message);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchmanagers();
  }, []);

  //sorting function
  const handleSort = (field) => {
    const isAsc = sortBy === field && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(field);

    const sortedData = [...managers].sort((a, b) => {
      if (a[field] < b[field]) return isAsc ? -1 : 1;
      if (a[field] > b[field]) return isAsc ? 1 : -1;
      return 0;
    });

    setmanagers(sortedData);
  };


  //filter
  // const filteredManagers = managers.filter((manager) => {
  //   const fullName = `${manager?.firstName?.toLowerCase()} ${manager?.lastName?.toLowerCase()}`;
  //   return (
  //     fullName.includes(searchTerm.toLowerCase()) ||
  //     (manager?.departmentId?.name?.toLowerCase().includes(searchTerm.toLowerCase())) ||  // Check department name
  //     manager?.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     (manager?.mobileNo && manager.mobileNo.toString().includes(searchTerm)) ||
  //     (manager?.adharNumber && manager.adharNumber.toString().includes(searchTerm)) ||
  //     (searchTerm.toLowerCase() === 'active' && manager?.status) ||
  //     (searchTerm.toLowerCase() === 'inactive' && !manager?.status)
  //   );
  // });


  // // Paginate the field officers data
  // const paginatedData = filteredManagers.slice(startIndex, endIndex);


  // validations
  const validateForm = () => {
    let isValid = true;

    // validation firstName
    if (firstName.trim() === '') {
      setfirstNameError("First name is required");
      isValid = false;
    } else {
      setfirstNameError("")
    }

    // validation lastName
    if (lastName.trim() === '') {
      setlastNameError("Last name is required");
      isValid = false;
    } else {
      setlastNameError("");
    }


    // email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.toString().trim())) {
      setemailError("Please enter a valid email address.");
      isValid = false
    } else {
      setemailError("")
    }

    // mobileNo validation
    const mobileRegex = /^[6-9]\d{9}$/; // Regex to match only numbers
    if (!mobileRegex.test(mobileNo.toString().trim())) {
      setmobileNoError('Mobile number is required and must be exactly 10 digits long, starting with a digit between 6 and 9.');
      isValid = false;
    } else {
      setmobileNoError("")
    }

    // validation adharNumber
    const adharRegex = /^\d{12}$/; // Regex to match only numbers
    if (!adharRegex.test(adharNumber.toString().trim())) {
      setadharNumberError('Aadhaar number is required and must be exactly 12 digits long.');
      isValid = false;
    } else {
      setadharNumberError("")
    }

    // validation departmentId
    if (departmentId.trim() === '') {
      setdepartmentIdError('Department ID is required');
      isValid = false;
    } else {
      setdepartmentIdError("")
    }

    return isValid;

  };

  //get departments api call
  const fetchdepartments = async () => {
    try {
      const response = await instance.get("Commissioner/getDepartments");
      setDepartments(response.data.departments);
    } catch (error) {
      console.error('Error fetching users:', error.message);
    }
  };

  useEffect(() => {
    fetchdepartments();
  }, []);


  //api call for create managers
  const addmanagers = async () => {
    try {
      if (!validateForm()) {
        return;
      }
      const response = await instance.post("Commissioner/Add_Manager",
        {
          firstName,
          lastName,
          mobileNo,
          email,
          adharNumber,
          departmentId,
        });
      if (response.data.success === true) {
        toast.success(response.data.message);
        fetchmanagers();
        handleClose1();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error);
      console.error(error);
    }
  }

  // Call your update API or function here
  const updatemanagerStatus = async () => {
    try {
      const response = await instance.post("Commissioner/updateManagerStatus", {
        manager_id: selectedmanager._id,
        status: selectedmanager.status === true ? false : true,
      });

      if (response.data.success === true) {
        toast.success(response.data.message);
        handleCloseDialog()
        fetchmanagers();

      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response);
    }
  }



  return (
    <React.Fragment>
      <Topbar />
      <div className='add_managers'>
        <React.Fragment>
          <BootstrapDialog
            onClose={handleClickOpen1}
            aria-labelledby="customized-dialog-title"
            open={open1}
            className="add_departments add_managers"
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Add Department Head
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose1}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    type="text"
                    value={firstName}
                    onChange={(e) => { setfirstName(e.target.value); setfirstNameError('') }}
                    error={Boolean(firstNameError)}
                    required
                  />
                  <FormHelperText error={Boolean(firstNameError)}>
                    {firstNameError}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    type="text"
                    value={lastName}
                    onChange={(e) => { setlastName(e.target.value); setlastNameError('') }}
                    error={Boolean(lastNameError)}
                    required
                  />
                  <FormHelperText error={Boolean(lastNameError)}>
                    {lastNameError}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    type="text"
                    value={email}
                    onChange={(e) => { setemail(e.target.value); setemailError('') }}
                    error={Boolean(emailError)}
                    required
                  />
                  <FormHelperText error={Boolean(emailError)}>
                    {emailError}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="outlined-basic"
                    label="Mobile Number"
                    variant="outlined"
                    type="number"
                    value={mobileNo}
                    onChange={(e) => { setmobileNo(e.target.value); setmobileNoError('') }}
                    error={Boolean(mobileNoError)}
                    onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value).toString().slice(0, 10)) }}
                    required
                  />
                  <FormHelperText error={Boolean(mobileNoError)}>
                    {mobileNoError}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="outlined-basic"
                    label="Adhar Number"
                    variant="outlined"
                    type="number"
                    value={adharNumber}
                    onChange={(e) => { setadharNumber(e.target.value); setadharNumberError('') }}
                    error={Boolean(adharNumberError)}
                    onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value).toString().slice(0, 12)) }}
                    required
                  />
                  <FormHelperText error={Boolean(adharNumberError)}>
                    {adharNumberError}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="filter_dropdown">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Department</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Department"
                        value={departmentId}
                        onChange={(e) => { setdepartmentId(e.target.value); setdepartmentIdError('') }}
                        error={Boolean(departmentIdError)}
                        required
                      >
                        {departments.map((department) => (
                          <MenuItem key={department?._id} value={department?._id}>{department.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormHelperText error={Boolean(departmentIdError)}>
                      {departmentIdError}
                    </FormHelperText>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="footer_button">
              <Button
                className="cancel_button"
                autoFocus
                onClick={handleClose1}
              >
                Cancel
              </Button>
              <Button
                className="add_button"
                autoFocus
                onClick={addmanagers}
              >
                Add
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </React.Fragment>
        {/* Status Change Confirmation Dialog */}
        <React.Fragment>
          <BootstrapDialog
            onClose={handleCloseDialog}
            aria-labelledby="customized-dialog-title"
            open={openDialog}
            className="add_departments"
          >
            <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
              Change Department Head Status
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <div className='employee-status'>
                <Typography>
                  Are you sure you want to change the status of Department Head <strong>{selectedmanager?.firstName} {selectedmanager?.lastName}</strong> to <strong>{selectedmanager?.status ? "Inactive" : "Active"}</strong>?
                </Typography>
              </div>
            </DialogContent>
            <DialogActions className="footer_button">
              <Button className="cancel_button" onClick={handleCloseDialog}>Cancel</Button>
              <Button className="add_button" onClick={() => updatemanagerStatus()}>Yes</Button>
            </DialogActions>
          </BootstrapDialog>
        </React.Fragment>
      </div>
      {loading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <section className="employees">
          <div className='employees-text'>
            <Typography variant='h5'>
              Department Head
            </Typography>
            {/* <Tooltip title={tooltipmanager}> */}
            <Button
              className="title_add_button"
              variant="outlined"
              onClick={handleClickOpen1}
            >
              Add Department Head
            </Button>
            {/* </Tooltip> */}
          </div>
          <div className='employees-main'>
            <div className='employees-content'>
              <div className='search-options'>
                {/* <TextField
                  placeholder='Search'
                  type='search'
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                /> */}
              </div>
              <div className='pagination-options'>
                <div className='pagination-animation'></div>
                <TablePagination
                  component="div"
                  count={managers?.length}
                  page={page - 1} // Adjust to be 0-based for MUI TablePagination
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[]}
                  labelDisplayedRows={({ from, to, count }) => (
                    <React.Fragment>
                      {formatNumber(from, 1)} to <span>{formatNumber(to, 2)}</span>
                      <span>of{' '} {count !== -1 ? count : `more than ${to}`}</span>
                    </React.Fragment>
                  )}
                />
                {/* <div className='filter'>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <FilterAltIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  className='filter-options'
                >
                  <div className='filter-menu'>
                    <Typography variant='h6'>Filter Departments</Typography>
                    <CloseIcon onClick={handleClose} />
                  </div>
                  <div className='filter-status'>
                    <React.Fragment>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel value="Active" control={<Radio />} label="Active" className='active' />
                          <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" className='inactive' />
                        </RadioGroup>
                      </FormControl>
                    </React.Fragment>
                    <div className="filter_dropdown">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Departments</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={age}
                          label="Departments"
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="filter_date_picker">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DatePicker", "DatePicker"]}
                        >
                          <DatePicker
                            label="Start"
                            defaultValue={dayjs("2022-04-17")}
                          />
                          <DatePicker
                            label="End"
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className='apply_fliters'>
                    <Button>Apply Fliter</Button>
                  </div>
                </Menu>
              </div> */}
              </div>
            </div>
            <div className='employee-table'>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell><div className='employee-index'> S.NO</div></TableCell>
                      <TableCell>
                        <div className='employee-name' onClick={() => handleSort('firstName' && 'lastName')}>
                          Department
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' onClick={() => handleSort('firstName' && 'lastName')}>
                          Name
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' onClick={() => handleSort('email')}>
                          Email
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' onClick={() => handleSort('mobileNo')}>
                          Mobile Number
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' onClick={() => handleSort('adharNumber')}>
                          Adhar Number
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' onClick={() => handleSort('status')}>
                          Status
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {managers.length > 0 ? (
                      managers.map((manager, index) => (
                        <TableRow key={manager._id}>
                          <TableCell>{startIndex + index + 1}</TableCell>
                          <TableCell>{manager?.departmentId?.name}</TableCell>
                          <TableCell>{manager.firstName} {manager.lastName}</TableCell>
                          <TableCell>{manager.email}</TableCell>
                          <TableCell>{manager.mobileNo}</TableCell>
                          <TableCell>{manager.adharNumber ? manager.adharNumber : "____"}</TableCell>
                          <TableCell>
                            <Tooltip title={changestatus}><span onClick={() => { handleopenDialog(manager) }} style={{ color: manager.status === true ? "#00A651" : " #EB5757", background: manager.status === true ? " #E5FCF0" : "#FFE8E8", cursor: "pointer" }}>{manager.status === true ? "Active" : "Inactive"}</span></Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography>No Data Found</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
};

export default Managers;
