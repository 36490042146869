import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography, TextField,
  InputAdornment, Button, MenuItem, Menu, IconButton, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, InputLabel, Select, Dialog, DialogContent,
  DialogTitle, DialogActions, Grid,
  Tooltip
} from '@mui/material';
import instance from '../../utils/api';
import '../../admin/styles/pages/dashboard.css';
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Topbar from '../../superadmin/layouts/Topbar';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));


const Employees = () => {
  const navigate = useNavigate();//navigate
  const [anchorEl, setAnchorEl] = React.useState(null);   //filter menu
  const open = Boolean(anchorEl);
  const [employees, setemployees] = useState([]);  //usestate for employees
  const [pagination, setpagination] = useState([]); //usestate pagination
  const [loading, setLoading] = useState(true); // usestate  loading
  const [sortOrder, setSortOrder] = useState('asc');    //usestate sorting
  const [sortBy, setSortBy] = useState('');  //usestate sorting
  const [page, setPage] = useState(1);  //usestate page
  const rowsPerPage = 10; // Fixed rows per page
  const [searchTerm, setSearchTerm] = useState('');// usestate Search
  const [department_id, setdepartment_id] = useState(""); //usestate department_id
  const [input, setinput] = useState(""); //usestate input
  const [departments, setDepartments] = useState([]);  //usestate for Departments
  const startIndex = (page - 1) * rowsPerPage;  // Calculate the start and end index for pagination
  const [openDialog, setOpenDialog] = useState(false);   //usestate for update poup
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const EmployeeCheckinDetails = "View Staff Check-In Details"; // Define the tooltip text
  const changestatus = "Staff Change Status"; // Define the tooltip text
  const filtertooltip = "Filter Menu"; // Define the tooltip text


  //filter open function
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  //filter close function
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  // Utility function to add leading zeros
  const formatNumber = (num, size) => {
    let s = String(num);
    while (s.length < size) s = "0" + s;
    return s;
  };

  //pagination page setpup
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1); // Adjust to be 1-based
  };


  // upadate employee status function
  const handleopenDialog = (employee) => {
    setOpenDialog(true);
    setSelectedEmployee(employee);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedEmployee(null);
  };

  //get departments api call
  const fetchdepartments = async () => {
    try {
      const response = await instance.get("Commissioner/getDepartments");
      setDepartments(response.data.departments);
    } catch (error) {
      console.error('Error fetching Departments:', error.message);
    }
  };

  useEffect(() => {
    fetchdepartments();
  }, []);

  //fetch employees
  const getAllEmployees = async () => {
    try {
      const response = await instance.post(`Commissioner/getAllEmployees?page=${page}`, { department_id, input });
      setemployees(response.data.employees);
      setpagination(response.data.pagination);
      handleClose1()
    } catch (error) {
      console.error('Error fetching employees:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllEmployees();
  }, [page]);

  //fetch employees
  const fetchemployeeReset = async () => {
    try {
      const response = await instance.post(`Commissioner/getAllEmployees?page=${1}`);
      setemployees(response.data.employees);
      setpagination(response.data.pagination);
      handleClose1();
    } catch (error) {
      console.error('Error fetching employees:', error.message);
    } finally {
      setLoading(false);
    }
  };

  //onsubmit function
  const onsubmit = () => {
    if (input || department_id) {
      setPage(1)
      // If both have values, call the fetchtickets function
      getAllEmployees();
      handleClose1()
      setinput("");
      setdepartment_id("")
    } else {
      // If either employeeId or status is missing, show an error message
      toast.error("Please enter input and department Id ");
    }
  }
  //handle sorting function
  const handleSort = (field) => {
    const isAsc = sortBy === field && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(field);

    const sortedData = [...employees].sort((a, b) => {
      if (a[field] < b[field]) return isAsc ? -1 : 1;
      if (a[field] > b[field]) return isAsc ? 1 : -1;
      return 0;
    });

    setemployees(sortedData);
  };


  // Call your update API or function here
  const updateEmployeeStatus = async () => {
    try {
      const response = await instance.post("Manager/updateEmployeeStatus", {
        id: selectedEmployee._id,
        status: selectedEmployee.status === true ? false : true,
      });

      if (response.data.success === true) {
        toast.success(response.data.message);
        handleCloseDialog()
        getAllEmployees();

      } else {
        toast.error('upadate employee status: ' + response.data.message);
      }
    } catch (error) {
      toast.error("Error updating employee status:");
      console.error('Error updating employee status:', error.message);
    }
  }

  
  // Filter the employees based on the search term
  // const filteredEmployees = employees.filter((employee) => {
  //   const searchTermLower = searchTerm.toLowerCase();
  //   const fullName = `${employee?.firstName.toLowerCase()} ${employee?.lastName.toLowerCase()}`;
  //   return (
  //     fullName.includes(searchTermLower) || // Check if the fullName includes the search term
  //     employee.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     employee.mobileNo.toString().includes(searchTerm) ||
  //     employee.adharNumber.toString().includes(searchTerm) ||
  //     employee?.departmentId?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||  employee?.departmentId?.name?.toUpperCase().includes(searchTerm.toUpperCase())||
  //     (searchTermLower.toLowerCase() === 'active' && employee.status) ||
  //     (searchTermLower.toLowerCase() === 'inactive' && !employee.status) 
  //   );
  // });
  // const endIndex = page * rowsPerPage;
  // // Paginate the field officers data
  // const paginatedData = filteredEmployees.slice(startIndex, endIndex);
  
  return (
    <React.Fragment>
      <Topbar />
      {/* Status Change Confirmation Dialog */}
      <React.Fragment>
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={openDialog}
          className="add_departments"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
            Change Staff Status
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <div className='employee-status'>
              <Typography>
                Are you sure you want to change the status of Staff <strong>{selectedEmployee?.firstName} {selectedEmployee?.lastName}</strong>  to <strong>{selectedEmployee?.status ? "Inactive" : "Active"}</strong>?
              </Typography>
            </div>
          </DialogContent>
          <DialogActions className="footer_button">
            <Button className="cancel_button" onClick={handleCloseDialog}>Cancel</Button>
            <Button className="add_button" onClick={() => updateEmployeeStatus()}>Yes</Button>
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
      {loading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <section className='employees'>
          <Typography variant='h5'>Staff</Typography>
          <div className='employees-main'>
            <div className='employees-content'>
              <div className='search-options'>
                {/* <TextField
                  placeholder='Search'
                  type='search'
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                /> */}
              </div>
              <div className='pagination-options'>
                <div className='pagination-animation'></div>
                <TablePagination
                  component="div"
                  count={employees?.length}
                  page={page - 1} // Adjust to be 0-based for MUI TablePagination
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[]}
                  labelDisplayedRows={({ from, to, count }) => (
                    <React.Fragment>
                      {formatNumber(from, 1)} to <span>{formatNumber(to, 2)}</span>
                      <span>of{' '} {count !== -1 ? count : `more than ${to}`}</span>
                    </React.Fragment>
                  )}
                />
                <div className='filter'>
                  <IconButton
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick1}
                  >
                    <Tooltip title={filtertooltip}><FilterAltIcon /></Tooltip>
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose1}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    className='filter-options'
                  >
                    <div className='filter-menu'>
                      <Typography variant='h6'>Filter Staff</Typography>
                      <CloseIcon onClick={handleClose1} />
                    </div>
                    <div className='filter-status'>
                      <div className="filter_dropdown staff-filter">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Department</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Department"
                            value={department_id}
                            onChange={(e) => setdepartment_id(e.target.value)}
                          >
                            <MenuItem value="">Select Department</MenuItem>
                            {departments.map((department) => (
                              <MenuItem key={department?._id} value={department?._id}>{department.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className='filter_dropdown  filter-tickets-1'>
                        <TextField
                          label="Search"
                          value={input}
                          onChange={(e) => setinput(e.target.value)}
                          fullWidth
                          placeholder='Please Enter Name Email Mobile No and Adhar No'
                          type='search' />
                      </div>
                    </div>
                    <div className='apply_fliters filter-btn'>
                      <Button className='reset-filter' onClick={() => {
                        setdepartment_id("");
                        setinput("");
                        setPage(1)
                        fetchemployeeReset();
                      }} >Reset Fliter</Button>
                      <Button onClick={() => onsubmit()}>Apply Fliter</Button>
                    </div>
                  </Menu>
                </div>
              </div>
            </div>
            <div className='employee-table'>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div className='employee-index'>
                          S.NO
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' onClick={() => handleSort('firstName' && 'lastName')}>
                          Department
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' onClick={() => handleSort('firstName' && 'lastName')}>
                          FullName
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' onClick={() => handleSort('email')}>
                          Email
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' onClick={() => handleSort('mobileNo')}>
                          Mobile Number
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' onClick={() => handleSort('adharNumber')}>
                          Adhar Number
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' onClick={() => handleSort('status')}>
                          Status
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' >
                          Action
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employees.length > 0 ? (
                      employees.map((employee, index) => (
                        <TableRow  key={employee._id}>
                          <TableCell>
                            {startIndex + index + 1}
                          </TableCell>
                          <TableCell>{employee?.departmentId?.name}</TableCell>
                          <TableCell>{employee.firstName} {employee.lastName}</TableCell>
                          <TableCell>{employee.email}</TableCell>
                          <TableCell>{employee.mobileNo}</TableCell>
                          <TableCell>{employee.adharNumber}</TableCell>
                          <TableCell>
                          <Tooltip title={changestatus}><span onClick={() => { handleopenDialog(employee) }} style={{ color: employee.status === true ? "#00A651" : " #EB5757", background: employee.status === true ? " #E5FCF0" : "#FFE8E8", cursor: "pointer" }}>{employee.status === true ? "Active" : "Inactive"}</span></Tooltip>
                          </TableCell>
                          <TableCell>
                            <div className='view-btn' onClick={() => navigate(`/superadmin/employee-details/${employee._id}`)}><Tooltip title={EmployeeCheckinDetails}><VisibilityIcon /></Tooltip></div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography>No Data Found</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  )
}

export default Employees;
