
import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

//superadmin imports
import Sidebar from "./superadmin/layouts/Sidebar";
import Login from "./superadmin/pages/Login";
import Forgotpassword from "./superadmin/pages/Forgotpassword";
import Dashboard from "./superadmin/pages/Dashboard";
import Departments from "./superadmin/pages/Departments";
import Employees from './superadmin/pages/Employees';
import Managers from './superadmin/pages/Managers';
import Tickets from './superadmin/pages/Tickets';
import Notifications from './superadmin/pages/Notifications';
import Profile from "./superadmin/pages/Profile";
import Changepassword from "./superadmin/pages/Changepassword";
import EmployeeCheckinDetails from "./superadmin/pages/EmployeeCheckinDetails";
import PublicUsers from "./superadmin/pages/PublicUsers";

//admin imports
import AdminSidebar from "./admin/layouts/Sidebar";
import AdminLogin from "./admin/pages/Login";
import AdminForgotpassword from './admin/pages/Forgotpassword'
import AdminDashboard from "./admin/pages/Dashboard";
import AdminEmployees from './admin/pages/Employees';
import AdminTickets from './admin/pages/Tickets'
import AdminNotifications from './admin/pages/Notifications';
import AdminProfile from './admin/pages/Profile';
import AdminChangepassword from './admin/pages/Changepassword';
import AdminEmployeeCheckinDetails from "./admin/pages/EmployeecheckInDetails";





const SuperadminRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Sidebar />} >
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/departments' element={<Departments />} />
        <Route path='/Staff' element={<Employees />} />
        <Route path="/employee-details/:empId" element={<EmployeeCheckinDetails />} />
        <Route path='/managers' element={<Managers />} />
        <Route path='/public-users' element={<PublicUsers />} />
        <Route path='/tickets' element={<Tickets />} />
        <Route path='/notifications' element={<Notifications />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/changepassword' element={<Changepassword />} />
      </Route>
    </Routes>
  );
};

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<AdminSidebar />} >
        <Route path='/dashboard' element={<AdminDashboard />} />
        <Route path='/staff' element={<AdminEmployees />} />
        <Route path='/tickets' element={<AdminTickets />} />
        <Route path='/notifications' element={<AdminNotifications />} />
        <Route path='/profile' element={<AdminProfile />} />
        <Route path='/changepassword' element={<AdminChangepassword />} />
        <Route path='/employee-details/:empId' element={<AdminEmployeeCheckinDetails />} />
      </Route>
    </Routes>
  );
};



function App() {
  const [tref, setTref] = useState();
  const token = localStorage.getItem('token');
  const role = localStorage.getItem("role");

  let authorization = false;
  if (token) {
    authorization = true;
  }
  if (!authorization) {
    return (
      <>
        <Routes>
          <Route index element={<Navigate to={'/superadmin'} />} />
          <Route path="/superadmin/*" element={<Navigate to={'/superadmin'} />} />
          <Route path="/admin/*" element={<Navigate to={'/admin'} />} />
          <Route path='/admin' element={<AdminLogin setTref={setTref} />} />
          <Route path='/superadmin' element={<Login setTref={setTref} />} />
          <Route path='/admin/forgotpassword' element={<AdminForgotpassword />} />
          <Route path='/superadmin/forgotpassword' element={<Forgotpassword />} />
        </Routes>
      </>
    );
  }
  if (authorization === true && role === "commissioner") {
    return (
      <Routes>
        <Route path='/superadmin/*' element={<SuperadminRoutes />} />
        <Route path="*" element={<Navigate to={"/superadmin/dashboard"} />} />
      </Routes>
    )
  }
  if (authorization === true && role === "Manager"){
    return (
      <Routes>
        <Route path='/admin/*' element={<AdminRoutes />} />
        <Route path="*" element={<Navigate to={"/admin/dashboard"} />} />
      </Routes>
    )
  }else{
    return (
      <>
        <Routes>
          <Route index element={<Navigate to={'/superadmin'} />} />
          <Route path="/superadmin/*" element={<Navigate to={'/superadmin'} />} />
          <Route path="/admin/*" element={<Navigate to={'/admin'} />} />
          <Route path='/admin' element={<AdminLogin setTref={setTref} />} />
          <Route path='/superadmin' element={<Login setTref={setTref} />} />
          <Route path='/admin/forgotpassword' element={<AdminForgotpassword />} />
          <Route path='/superadmin/forgotpassword' element={<Forgotpassword />} />
        </Routes>
      </>
    );
  }
  

}
export default App;


